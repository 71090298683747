import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { ROUTES } from 'constants/routes';
import { getCookies } from 'helpers/cookies';
import { LANG } from 'constants/index';
import router from 'next/router';
import LoaderItem from '../ContentLoader/LoaderItem';

const Categories = ({ data }) => {
  const [mediaType, setMediaType] = useState();
  useEffect(() => {
    switch (router.pathname) {
      case ROUTES.HOME: setMediaType('photography'); break;
      case ROUTES.VECTOR: setMediaType('illustration'); break;
      case ROUTES.FOOTAGE: setMediaType('footage'); break;
      case ROUTES.AUDIO: setMediaType('audio'); break;
      default: break;
    }
  }, [router.pathname]);
  return (
    <>
      { isEmpty(data) ? <LoaderItem length={4} /> : (
        <Row gutter={[24, 40]}>
          {(() => {
            const result = [];
            for (let i = 0; i <= data?.length / 4; i += 1) {
              result.push(
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  key={i.toString()}
                >
                  {data
                    .slice(i * 4, (i + 1) * 4)
                    .map((item, index) => (
                      <div
                        className="category-item mb-2"
                        key={index.toString()}
                      >
                        <Link
                          href={`${ROUTES.SEARCH_IMAGE}?keyword=${item?.keyword}&media_type=${mediaType}&lang=${getCookies(LANG)}`}
                          className="category-item-title"
                          prefetch={false}
                        >
                          {item?.keyword}
                        </Link>
                      </div>
                    ))}
                </Col>,
              );
            }
            return result;
          })()}
        </Row>
      )}
    </>
  );
};
Categories.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

Categories.defaultProps = {
  data: [],
};
export default Categories;
