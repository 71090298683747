import {
  Layout, Tabs, Select, Button,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getTransText } from 'common/translate';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getCookies } from 'helpers/cookies';
import { ACCESS_TOKEN, TAB_SHARE_WITH_ME_ACTIVE_KEY } from 'constants/index';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const TabContent = ({
  children, tabOne, tabTwo, tabThree, activeKey, setTabCollection, openCreateCollectionModal,
}) => {
  const [isRecentyleView, setIsRecentyleView] = useState(false);

  const options = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
  ];
  const [activeSelect, setActiveSelect] = useState(options[0]);

  const onClickTabs = (key) => {
    setTabCollection(key);

    const index = options.findIndex((x) => x.value === key);
    setActiveSelect(options[index]);
  };

  const onClickSelect = (value) => {
    setActiveSelect(value);
    setTabCollection(value.key);
  };
  useEffect(() => {
    if (!isEmpty(getCookies(ACCESS_TOKEN))) {
      setIsRecentyleView(true);
    }
  }, []);
  return (
    <Layout className="home-tab">
      <Select
        labelInValue
        value={activeSelect}
        className="select-hometab"
        onChange={onClickSelect}
      >
        <Option className="option-sl-tab" value="1">
          {tabOne}
        </Option>
        <Option className="option-sl-tab" value="2">
          {tabTwo}
        </Option>
        {isRecentyleView && (
        <Option className="option-sl-tab" value="3">
          {tabThree}
        </Option>
        )}
      </Select>
      <Tabs
        activeKey={activeKey}
        onTabClick={onClickTabs}
        tabBarExtraContent={(openCreateCollectionModal
          && activeKey !== TAB_SHARE_WITH_ME_ACTIVE_KEY) ? (
            <Button
              type="primary"
              shape="round"
              className="global-btn primary-btn btn-with-icon only-desktop"
              icon={<PlusOutlined />}
              onClick={openCreateCollectionModal}
            >
              {getTransText('createNew')}
            </Button>
          ) : (<></>)}
        centered
      >
        { children }
      </Tabs>
    </Layout>
  );
};

TabContent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any),
  tabOne: PropTypes.string,
  tabTwo: PropTypes.string,
  tabThree: PropTypes.string,
  activeKey: PropTypes.string,
  setTabCollection: PropTypes.func,
  openCreateCollectionModal: PropTypes.func,
};

TabContent.defaultProps = {
  children: {},
  tabOne: getTransText('recommended'),
  tabTwo: getTransText('categoryImage'),
  tabThree: getTransText('recentViewed'),
  activeKey: 1,
  setTabCollection: null,
  openCreateCollectionModal: null,
};
export default TabContent;
