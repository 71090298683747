import { Layout } from 'antd';
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { getStorage } from 'helpers/storage';
import SearchBar from 'components/atoms/SearchBar/SearchBar';
import MainContexts from 'stores/context/MainContexts';
import {
  ACCESS_TOKEN, ADMIN_ACCOUNT, GET_SETTING, ROUTES_LEGACY,
  DEFAULT_HOMEPAGE_HEADER_TEXT, DEFAULT_HOMEPAGE_BODY_TEXT,
} from 'constants/index';
import { useRouter } from 'next/router';
import { setValueDefaultDropdown } from 'common/common';
import * as cookie from 'helpers/cookies';
import IcEditSetting from 'components/icons/IcEditSetting';

const Banner = () => {
  const [background, setBackground] = useState('');
  const { initDataProvider, dataUserProfile } = useContext(MainContexts);
  const mainImage = initDataProvider?.getSettings?.main_image_path;
  const homeMessage = initDataProvider?.getSettings?.homepage_message;
  const accessToken = cookie.getCookies(ACCESS_TOKEN);
  const router = useRouter();
  const ref = useRef(null);

  useEffect(() => {
    const getSetting = getStorage(GET_SETTING);
    setBackground(getSetting?.main_image_path);
    return null;
  }, []);

  return (
    <div className="banner-wrapper" ref={ref}>
      <div className="search-bar-landing">
        <SearchBar isHome defaultValueSelector={setValueDefaultDropdown(router)} />
      </div>
      <Layout
        className="home-banner"
        style={{ backgroundImage: `url(${mainImage ?? background})` }}
      >
        <div className="banner-title-container">
          {homeMessage !== '' ? (
            <h1>
              { homeMessage }
            </h1>
          ) : (
            <>
              <h1>
                {DEFAULT_HOMEPAGE_HEADER_TEXT}
              </h1>
              <h1 className="body-text-homepage">
                {DEFAULT_HOMEPAGE_BODY_TEXT}
              </h1>
            </>
          )}
        </div>
        {accessToken && ADMIN_ACCOUNT.includes(dataUserProfile?.is_admin) && (
        <div onClick={() => router.push(ROUTES_LEGACY.CUSTOMIZE)} className="btn-edit-settings-landing-page" role="button" tabIndex="0" aria-hidden="true">
          <IcEditSetting />
        </div>
        )}
      </Layout>
    </div>
  );
};
export default Banner;
