import PropTypes from 'prop-types';
import { ICON_ADD_COLOR } from 'constants/index';

const IcEditSetting = ({
  fillColor,
  width,
  height,
}) => (
  <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="25" fill={fillColor} fillOpacity="0.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.4062 19.6563L30.7812 24.0313L19.8438 34.9688L14.375 36.0625L15.4688 30.5938L26.4062 19.6563ZM35.1562 15.2813C36.364 16.4895 36.3642 18.4472 35.1569 19.6557L32.9688 21.8438L30.7935 19.6685C30.7894 19.6644 30.7853 19.6604 30.7812 19.6563L30.7687 19.6438L28.5938 17.4688L30.769 15.2936C30.7731 15.2895 30.7772 15.2854 30.7812 15.2813C31.9892 14.0729 33.9483 14.0729 35.1562 15.2813Z" fill="white" />
  </svg>

);
IcEditSetting.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

IcEditSetting.defaultProps = {
  fillColor: ICON_ADD_COLOR,
  width: '50',
  height: '50',
};
export default IcEditSetting;
