import {
  Col, Row, Skeleton,
} from 'antd';
import PropTypes, { number } from 'prop-types';
import React from 'react';

const ContentLoaderImage = ({
  data, gutter, className, aspectRatio, isBlog, spanSize,
}) => (
  <>
    <Row gutter={gutter} className={`${!isBlog ? '' : 'ant-row-text'}`}>
      {[...Array(data)].map((item, index) => (
        <Col
          xs={spanSize[0]}
          sm={spanSize[1]}
          md={spanSize[2]}
          lg={spanSize[3]}
          xl={spanSize[4]}
          xxl={spanSize[5]}
          key={index.toString()}
          className={`${!isBlog ? 'jutified-img-wrapper' : 'jutified-img-wrapper over-flow-unset'} ${aspectRatio}`}
        >
          {!isBlog ? (
            <div className={`content-loader-image ${className}`}>
              <Skeleton.Image />
            </div>
          ) : (
            <div className="content-loader-image blog-content-loader ">
              <Skeleton.Image />
              <Skeleton active paragraph={false} />
            </div>
          )}

        </Col>
      ))}
    </Row>

  </>
);
ContentLoaderImage.propTypes = {
  data: PropTypes.number,
  gutter: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  aspectRatio: PropTypes.string,
  isBlog: PropTypes.bool,
  spanSize: PropTypes.arrayOf(PropTypes.any),
};

ContentLoaderImage.defaultProps = {
  data: number,
  gutter: [],
  className: '',
  aspectRatio: '',
  isBlog: false,
  spanSize: [],
};

export default ContentLoaderImage;
