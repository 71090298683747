/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ContentSection = ({
  title,
  children,
  className = '',
}) => (
  <div className={cx('content-section-wrapper', className)}>
    {title && (
      <h3 className="content-section-title pb-5">{title}</h3>
    )}
    <div className="content-section-body">{children}</div>
  </div>
);
ContentSection.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  className: PropTypes.string,
};
ContentSection.defaultProps = {
  children: null,
  className: null,
  title: null,
};
export default ContentSection;
