import { Image } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import { getCookies } from 'helpers/cookies';
import { LANG } from 'constants/index';
import { detectedLang } from 'common/translate';
import { useRouter } from 'next/router';

const ImageHoverEffect = ({
  data, className, content, description, link, dynamicData, isPortfolio,
}) => {
  const router = useRouter();
  const onTitleClick = () => {
    router.push(`${link}&lang=${getCookies(LANG)}`);
  };
  return (
    <div className={isPortfolio ? 'portfolio-image-hover' : ''}>
      <div
        aria-hidden
        role="button"
        className="trending-caption-wrapper"
        onClick={onTitleClick}
      >
        <Image
          preview={false}
          src={data}
          className={className}
        />
        <div className="trending-caption"><Text>{!dynamicData ? (content && detectedLang(content)) : content}</Text></div>
      </div>
      <div
        aria-hidden
        role="button"
        className={`trending-caption-wrapper${isPortfolio ? ' portfolio-desc' : ''}`}
        onClick={onTitleClick}
      >
        {description && (
        <div className={isPortfolio ? 'portfolio-text' : 'pt-4'}>
          <span className="business-vector-text">
            {description}
          </span>
        </div>
        )}
      </div>
    </div>
  );
};
ImageHoverEffect.propTypes = {
  data: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  dynamicData: PropTypes.bool,
  isPortfolio: PropTypes.bool,
};

ImageHoverEffect.defaultProps = {
  data: null,
  className: null,
  content: null,
  description: null,
  link: null,
  dynamicData: false,
  isPortfolio: false,
};
export default ImageHoverEffect;
